//const user = store.getters.getuser;
//import store from '../store'
import Vue from 'vue';
import VueToastr2 from 'vue-toastr-2';
import 'vue-toastr-2/dist/vue-toastr-2.min.css';
import mprofileImage from '@/assets/images/mprofile.svg';
import fprofileImage from '@/assets/images/fprofile.svg';
import clientProfile from '@/assets/images/client_profile.png';

window.toastr = require('toastr');

Vue.use(VueToastr2);

import _ from 'lodash';
export default {
  computed: {
    btnLoder: {
      get() {
        console.log(this.$route.name.trim());
        return this.$store.getters['getBtnLoder'];
        // return false;
      },
      set(value) {
        this.value = value;
      },
    },

    showLoading: {
      // return this.$store.getters["isloading"];
      //return false;

      get() {
        //console.log(this.$store.getters["isloading"]);
        return this.$store.getters['isloading'];
        // return false;
      },
      set(value) {
        this.value = value;
      },
    },
    currentRoute() {
      return this.$route.name.trim();
    },
  },

  mounted() {
    // alert(this.$store.getters['auth/getuser'])

    if (this.userdata == null) {
      //this.userdata = this.$store.getters['auth/getuser'];
    }
    //this.userdata = this.$store.getters['auth/getuser'];
    // this.toggleBodyClass('addClass' ,"hidden");
  },

  watch: {},

  data: () => ({
    enableSearch: false,
    formSubmited: false,
    tenantId: '',
    userdata: null,
    openFilter: false,
    closeFileSelection: false,
    enableFileSelectionFromDocuments: true,
  }),

  methods: {
    checkCharacter(str = '', char = '') {
      if (str != '' && char != '') {
        if (str.indexOf(char)) {
          return char + str;
        } else {
          return str;
        }
      } else {
        return str;
      }
    },
    checkProperty(object = null, mainKey = '', subKey = '') {
      if (object != null && mainKey != '') {
        if (_.has(object, mainKey)) {
          if (subKey != '') {
            if (_.has(object[mainKey], subKey)) {
              return object[mainKey][subKey];
            } else {
              return '--';
            }
          } else {
            return object[mainKey];
          }
        } else {
          return '--';
        }
      } else {
        return '--';
      }
    },
    setAltImg(event) {
      event.target.src = clientProfile;
    },
    getProfilePhoto(event, gender = 'M') {
      if (gender == 'M' || gender == 'O') {
        event.target.src = mprofileImage;
      } else if (gender == 'F') {
        event.target.src = fprofileImage;
      } else {
        event.target.src = mprofileImage;
      }
    },
    notify({ message = '', title = '', type = 'success' }) {
      if (type == 'success') {
        // this.$toastr.success(message, title, {});
        this.$toastr.success(message, title, {
          positionClass: 'toast-top-center', // Center top position
        });
      } else {
        this.$toastr.error(message, title, {});
      }
    },
    showLoading() {
      this.show_loading = true;
      this.formSubmited = true;
    },
    closeLoading() {
      this.show_loading = false;
      this.formSubmited = false;
    },
    log(data) {
      console.log(data);
    },
    toggleFilter() {
      this.openFilter = this.openFilter ? false : true;
      if (this.openFilter) {
        this.toggleBodyClass('addClass', 'hidden');
      } else {
        this.toggleBodyClass('removeClass', 'hidden');
      }
    },
    finalSaveselectedFiles() {
      this.closeFileSelection = false;
      //$emit("close_file_selection");
    },

    check(e, document) {
      let alreadySelectedFIles =
        this.$store.getters['documents/getSelectedFiles'];
      let id = document['_id'];
      document['doc_id'] = document['_id'];
      let doc = document;
      doc = _.omit(doc, ['_id']);

      if (e) {
        //check selectedFile Alreasy Exists or not
        if (_.findIndex(alreadySelectedFIles, { doc_id: id }) <= -1) {
          if (!_.has(doc, 'path') && _.has(doc, 'url'))
            doc['path'] = doc['url'];

          doc['upload_from_documents'] = true;
          this.$store.dispatch('documents/saveStoreSelectedDoc', doc);
        }
      } else {
        //check selectedFile Alreasy Exists or not
        if (_.findIndex(alreadySelectedFIles, { doc_id: id }) >= -1) {
          let tempFiles = _.filter(alreadySelectedFIles, (obj) => {
            return obj['doc_id'] != id;
          });
          this.$store.dispatch('documents/removeStoreSelectedDoc', tempFiles);
        }
      }

      this.$emit('update_selected_files');
    },
    fmtMSS(n) {
      // accepts seconds as Number or String. Returns m:ss
      // return( s -         // take value s and subtract (will try to convert String to Number)
      //         ( s %= 60 ) // the new value of s, now holding the remainder of s divided by 60
      //                     // (will also try to convert String to Number)
      //       ) / 60 + (    // and divide the resulting Number by 60
      //                     // (can never result in a fractional value = no need for rounding)
      //                     // to which we concatenate a String (converts the Number to String)
      //                     // who's reference is chosen by the conditional operator:
      //         9 < s       // if    seconds is larger than 9
      //         ? ':'       // then  we don't need to prepend a zero
      //         : ':0'      // else  we do need to prepend a zero
      //       ) + s ;       // and we add Number s to the string (converting it to String as well)

      var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      if (rhours <= 9) rhours = '0' + rhours;
      if (rminutes <= 9) rminutes = '0' + rminutes;

      return rhours + ':' + rminutes;
    },

    findmsDoctype(ext = '') {
      let docTypes = [
        'doc',
        'docx',
        'txt',
        'odt',
        'rtf',
        'dotm',
        'wps',
        'xml',
        'xl',
        'xlsx',
        'xla',
        'xlsb',
      ];
      let imagTypes = ['jpeg', 'jpg', 'png'];
      let pdfTypes = ['pdf'];
      if (
        _.findIndex(docTypes, (vl) => {
          return vl == ext;
        }) != -1
      ) {
        return 'office';
      } else if (
        _.findIndex(imagTypes, (vl) => {
          return vl == ext;
        }) != -1
      ) {
        return 'image';
      } else if (
        _.findIndex(pdfTypes, (vl) => {
          return vl == ext;
        }) != -1
      ) {
        return 'pdf';
      } else {
        return false;
      }
    },
    download_file(value) {
      if (_.has(value, 'path')) {
        value['url'] = value['path'];
      }
      /*
      value.url = value.url.replace(
        "https://lnaccounting.s3.ap-south-1.amazonaws.com/",
        ""
      );
      */
      // let postdata = { keyName: value.path , "taskId": this.taskDetails['_id']};
      let postdata = { keyName: value.url };

      this.$store.dispatch('getSignedUrl', postdata).then((response) => {
        window.open(response.data.result.data);
      });
    },

    randomStr(length = 5) {
      var result = '';
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        );
      }
      return result;
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  },
  components: {},
  created() {
    document.title = 'TaxHero';
    this.userdata = this.$store.getters['auth/getuser'];
  },
  beforeCreate() {
    //alert();

    this.userdata = this.$store.getters['auth/getuser'];
    //console.log(this.showLoading);
  },
};

/*

 $(document).ready(function(){
  $('#myselect').selectpicker();
  ///// Filters Section
  $('.filters_btn').click(function(){
    $('.filters_sec').toggleClass('open');
  });
  $('.filters_layer, .filter_close').click(function(){
    $('.filters_sec').removeClass('open');
  });

  $('.notes_header ul li a').click(function(){
    $(this).addClass('active');
    $(this).parent().siblings().find('a').removeClass('active');
  });

  $('.accordian_title').click(function(){
    $(this).toggleClass('open');
    $(this).parent().find('.accordian_cnt_wrap').slideToggle();
    $(this).parent().siblings().find('.accordian_cnt_wrap').slideUp();
    $(this).parent().siblings().find('.accordian_title').removeClass('open');
  });


  $("#duedate").datepicker({
    autoclose: true,
    todayHighlight: true
  }).datepicker();

  $("#datefiling").datepicker({
    autoclose: true,
    todayHighlight: true
  }).datepicker();


});
*/
